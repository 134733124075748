<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%" eager>
    <v-card v-if="paginaCarregada">
      <v-card-title>
        <div class="card-title">
          {{ $tc('title.metas', 1)}}
        </div>
      </v-card-title>
      <v-card-text class="justify">
        <span v-if="isMetaPreviaAtingida || isMetaFinalAtingida">
          {{ $tc('label.realizado_previa_atingido', 1) }}
        </span>
        <span v-else>
          {{ $tc('label.realizado_previa_nao_atingido', 1) }}
        </span>
      </v-card-text>
      <v-card-text class="justify">
        <v-data-table
          class="elevation-1"
          :headers="cabecalho"
          :items="metas"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('label.tabela_sem_conteudo')">
          <template v-slot:[`item.vlrMetaDe`]="{ item }">
            <div :style="destacarLinha(item)">
              <span v-if="isTipoGatilhoQuantidade">{{ item.vlrMetaDe }}</span>
              <span v-else>{{ getMoney(item.vlrMetaDe) }}</span>
            </div>
          </template>
          <template v-slot:[`item.vlrMetaAte`]="{ item }">
            <div :style="destacarLinha(item)">
              <span v-if="isTipoGatilhoQuantidade">{{ item.vlrMetaAte }}</span>
              <span v-else>{{ getMoney(item.vlrMetaAte) }}</span>
            </div>
          </template>
          <template v-slot:[`item.vlrMaximoFaixa`]="{ item }">
            <div :style="destacarLinha(item)">
              {{ getMoney(item.vlrMaximoFaixa) }}
            </div>
          </template>
          <template v-slot:[`item.vlrBonificacao`]="{ item }">
            <div :style="destacarLinha(item)">
              <span v-if="!isPercentual">{{ getMoney(item.vlrBonificacao) }}</span>
              <span v-if="isPercentual">{{ item.vlrBonificacao + '%' }}</span>
            </div>
          </template>
          <template v-slot:[`item.faixaAtingida`]="{ item }">
            <div :style="destacarLinha(item)">
              <span v-if="isFaixaAtingida(item)">{{ $tc('label.faixa_atingida', 1) }}</span>
              <span v-else-if="!isMetaAtingida && item.faixaMinima">{{ $tc('label.faixa_minima', 1) }}</span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text v-if="realizadoPrevia">
        <template >
          <h3 class="mb-03" style="color: red" >
            <v-icon large color="red"> error_outline </v-icon>
            {{ $t('label.realizado_previa_msg') }}
          </h3>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="fecharModal()">{{ $t('label.voltar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Confirm from '@/shared-components/dialog/Confirm';
import { getMoney } from '@/common/functions/helpers';
import PlanejamentoAcaoFormUsuarioCliente from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormUsuarioCliente';
import ApuracaoAcaoArquivos from './ApuracaoAcaoArquivos';

export default {
  name: 'ModalMetasGatilho',
  data() {
    return {
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      panel: 0,

      metas: null,
      dialog: false,

      cabecalho: [],
      realizadoPrevia: false,
      isPercentual: false,
      isTipoGatilhoQuantidade: false,
      paginaCarregada: false,
    };
  },
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    Confirm,
    ApuracaoAcaoArquivos,
  },
  computed: {
    isMetaPreviaAtingida() {
      if (this.metas && this.metas.length && this.realizadoPrevia) {
        return this.metas.find((meta) => meta.faixaAtingidaPrevia);
      }
      return false;
    },
    isMetaFinalAtingida() {
      if (this.metas && this.metas.length && !this.realizadoPrevia) {
        return this.metas.find((meta) => meta.faixaAtingida);
      }
      return false;
    },
    isMetaAtingida() {
      return this.isMetaPreviaAtingida || this.isMetaFinalAtingida;
    },
  },
  mounted() {

  },
  methods: {
    getMoney,
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    abrirModal(metas, tipoRealizado, tipoCalculo, tipoGatilho) {
      this.setarCabecalho(tipoCalculo);
      this.metas = metas;
      this.realizadoPrevia = tipoRealizado === 'realizadoPrevia';
      this.isTipoGatilhoQuantidade = tipoGatilho === 'QUANTIDADE';
      this.paginaCarregada = true;
      this.$forceUpdate();
      this.open();
    },
    fecharModal() {
      this.close();
      this.apuracao = null;
      this.paginaCarregada = false;
    },
    destacarLinha(item) {
      if (this.isFaixaAtingida(item) || (this.realizadoPrevia && !this.isMetaPreviaAtingida && item.faixaMinima)
        || (!this.realizadoPrevia && !this.isMetaFinalAtingida && item.faixaMinima)) {
        return 'white-space: nowrap; color:#0000FF;';
      }
      return 'white-space: nowrap;';
    },
    isFaixaAtingida(item) {
      return (item.faixaAtingida && !this.realizadoPrevia) || (item.faixaAtingidaPrevia && this.realizadoPrevia);
    },
    headersPercentual() {
      return [
        {
          text: this.$tc('label.meta_de', 1), value: 'vlrMetaDe', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.meta_ate', 1), value: 'vlrMetaAte', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlrBonificacao', sortable: false, width: '20%',
        },
        {
          text: this.$tc('', 1), value: 'faixaAtingida', sortable: false, width: '25%',
        },
      ];
    },
    headersValorPeca() {
      return [
        {
          text: this.$tc('label.meta_de', 1), value: 'vlrMetaDe', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.meta_ate', 1), value: 'vlrMetaAte', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlrBonificacao', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.valor_maximo_faixa', 1), value: 'vlrMaximoFaixa', sortable: false, width: '25%',
        },
        {
          text: this.$tc('', 1), value: 'faixaAtingida', sortable: false, width: '25%',
        },
      ];
    },
    setarCabecalho(tipoCalculo) {
      if (tipoCalculo !== 'VALOR_POR_PECA') {
        this.isPercentual = tipoCalculo === 'PERCENTUAL';
        this.cabecalho = this.headersPercentual();
        return;
      }
      this.cabecalho = this.headersValorPeca();
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
