<template>
  <div class="ApuracaoAcaoDetalhesProdutos" v-if="paginaCarregada">
     <v-card-title>
      <v-row>
        <v-col sm="6">
        <span >{{ $tc('label.produto', 2)}}</span>
        </v-col>
        <v-col align="right" sm="6">
         <v-btn style="margin:0;text-align: center"
          @click="consultarNotas"
          color="primary"
          v-if="possuiNd">
          {{$t('label.ver_nota_debito')}}
        </v-btn>
        </v-col>
      </v-row>
      </v-card-title>
      <v-data-table
        id="tabela_linha_produto"
        :headers="headers"
        :items="produtos"
        :items-per-page="10"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        class="tabelaProdutos elevation-1"
      >
        <template v-slot:[`item.apuracaoFinal`]="{ item }">
          {{ getMoney(item.apuracaoFinal) }}
          <h4 v-if="item.possuiValorMaximoNd && isCalculoNDMaximoProduto" class="orange--text"> {{ '%execução: ' + valorExecucao(item.apuracaoFinal, item.valorMaximoNd) + '%'}}</h4>
        </template>
        <template v-slot:[`item.apuracaoTotal`]="{ item }">
          {{ getMoney(item.apuracaoTotal) }}
          <h4 v-if="item.possuiValorMaximoNd && isCalculoNDMaximoProduto" class="orange--text"> {{ '%execução: ' + valorExecucao(item.apuracaoTotal, item.valorMaximoNd) +'%' }}</h4>
        </template>
        <template v-slot:[`item.realizadoPrevia`]="{ item }">
          <v-row justify="center" v-if="item.metas && item.metas.length">
            <v-col cols="12" sm="10" class="px-0 mx-0 mt-1">
              <v-progress-linear
                background-color="#757575"
                color="primary"
                height="85%"
                rounded
                :value="valorBarraProgresso(item.metas, 'realizadoTotal')"
              >
                <template v-slot:default="{ value }">
                  <span v-if="isGatilhoMonetario">{{ getMoney(item.realizadoPrevia) }}</span>
                  <span v-else-if="isGatilhoPercentual">{{ getPercent(item.realizadoPrevia) }}</span>
                  <span v-else>{{ item.realizadoPrevia }}</span>
                </template>
              </v-progress-linear>
            </v-col>
            <v-col cols="12" sm="2" class="px-0 mx-0 pt-1 mt-1">
              <v-btn
                icon
                v-if="item.metas && item.metas.length"
                @click="abrirModalMetasGatilho(item, 'realizadoPrevia')"
              >
                <v-icon>visibility</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-else>
            <span v-if="isGatilhoMonetario">{{ getMoney(item.realizadoPrevia) }}</span>
            <span v-else-if="isGatilhoPercentual">{{ getPercent(item.realizadoPrevia) }}</span>
            <span v-else>{{ item.realizadoPrevia }}</span>
          </div>
        </template>
        <template v-slot:[`item.realizadoFinal`]="{ item }">
          <v-row justify="center" v-if="item.metas && item.metas.length">
            <v-col cols="12" sm="10" class="px-0 mx-0 mt-1">
              <v-progress-linear
                background-color="#757575"
                color="primary"
                height="85%"
                rounded
                :value="valorBarraProgresso(item.metas, 'realizadoFinal')"
              >
                <template v-slot:default="{ value }">
                  <span v-if="isGatilhoMonetario">{{ getMoney(item.realizadoFinal) }}</span>
                  <span v-else-if="isGatilhoPercentual">{{ getPercent(item.realizadoFinal) }}</span>
                  <span v-else>{{ item.realizadoFinal.toFixed(0) }}</span>
                </template>
              </v-progress-linear>
            </v-col>
            <v-col cols="12" sm="2" class="px-0 mx-0 pt-1 mt-1">
              <v-btn
                icon
                v-if="item.metas && item.metas.length"
                @click="abrirModalMetasGatilho(item, 'realizadoFinal')"
              >
                <v-icon>visibility</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-else>
            <span v-if="isGatilhoMonetario">{{ getMoney(item.realizadoFinal) }}</span>
            <span v-else-if="isGatilhoPercentual">{{ getPercent(item.realizadoFinal) }}</span>
            <span v-else>{{ item.realizadoFinal.toFixed(0) }}</span>
          </div>
        </template>
        <template v-slot:[`item.codNomeProduto`]="{ item }">
          <v-btn
            icon
            large
            v-if="item.qtdProduto > 0"
            @click="visualizarLinhaProduto(item, false)"
            >
            <v-icon>visibility</v-icon>
          </v-btn>
          <span v-if="item.qtdProduto > 0">{{ item.qtdProduto === 1 ? item.listaProdutos[0].codProduto : `${ item.qtdProduto } ${ $tc('label.sku_selecionado', 2)}` }}</span>
          <span v-else> {{ $t('label.todos') }} </span>
        </template>
        <template v-slot:[`item.codNomeProdutoApurado`]="{ item }">
          <v-btn
            icon
            large
            v-if="item.qtdProdutosApurados > 0"
            @click="visualizarLinhaProduto(item, true)"
            >
            <v-icon>visibility</v-icon>
          </v-btn>
          <span v-if="item.qtdProdutosApurados > 0">{{ item.qtdProdutosApurados === 1 ? item.listaProdutosApurados[0].codProduto : `${ item.qtdProdutosApurados } ${ $tc('label.sku_selecionado', 2)}` }}</span>
        </template>
        <template v-slot:[`item.verbaNaoCapturada`]="{ item }">
          <span v-if="item.possuiValorMaximoNd">{{ getMoney(item.verbaNaoCapturada) }}</span>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.verbaAcimaDoTeto`]="{ item }">
          <span v-if="item.possuiValorMaximoNd">{{ getMoney(item.verbaAcimaDoTeto) }}</span>
          <span v-else> - </span>
        </template>
        <template  v-slot:[`item.valorMaximoNd`]="{ item }">
          <span v-if="item.possuiValorMaximoNd">{{ getMoney(item.valorMaximoNd) }}</span>
          <span v-else> - </span>
        </template>
        <template  v-slot:[`item.valor`]="{ item }">
          <span v-if="isPercentual"> {{ getPercent(item.valor) }} </span>
          <span v-else> {{ getMoney(item.valor) }} </span>
        </template>
      </v-data-table>
      <modal-metas-gatilho
        ref="modalMetasGatilho"/>
      <apuracao-acao-verba-variavel-modal-selecao-produto
        ref="modalSelecaoProduto"
        :is-sellout="isSellout"
        :linha-produto="linhaProduto"
        :is-linha-produto-apurado="isLinhaProdutoApurado"
      />
  </div>
</template>
<script>
import { getMoney, getNumber, getPercent } from '@/common/functions/helpers';
import ModalMetasGatilho from './ModalMetasGatilho';
import ApuracaoAcaoVerbaVariavelModalSelecaoProduto from './ApuracaoAcaoVerbaVariavelModalSelecaoProduto';

export default {
  name: 'ApuracaoAcaoDetalhesProdutos',
  props: {
    resumoExec: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      produtos: [],
      resumo: {},
      tipoGatilho: null,
      tipoCalculo: null,
      corBotao: null,
      itemIcon: null,
      linhaProduto: {},
      paginaCarregada: false,
      isLinhaProdutoApurado: false,
      header: [],
    };
  },
  components: {
    getMoney,
    getPercent,
    ModalMetasGatilho,
    ApuracaoAcaoVerbaVariavelModalSelecaoProduto,
  },
  computed: {
    headers() {
      if (this.resumoExec.temGatilho) {
        return this.headersGatilho();
      }
      if (!this.resumoExec.temGatilho && this.resumoExec.tipoCalculo === 'PERCENTUAL') {
        return this.headersPercentual();
      }
      if (!this.resumoExec.temGatilho && this.resumoExec.tipoCalculo === 'VALOR_POR_PECA') {
        return this.headersValorPeca();
      }
      return null;
    },
    isPercentual() {
      return this.resumoExec.tipoCalculo === 'PERCENTUAL';
    },
    isMonetario() {
      return this.resumoExec.tipoCalculo === 'FIXO';
    },
    isGatilhoMonetario() {
      return this.resumoExec.tipoGatilho === 'SELLIN_VALOR'
          || this.resumoExec.tipoGatilho === 'SELLOUT_VALOR';
    },
    isGatilhoPercentual() {
      return this.resumoExec.tipoGatilho === 'SELLIN_PERCENTUAL';
    },
    isGatilhoQuantidade() {
      return this.resumoExec.tipoGatilho === 'QUANTIDADE';
    },
    possuiNd() {
      return this.resumoExec.possuiNd;
    },
    isSellout() {
      return this.resumoExec.modalidade === 'SELLOUT';
    },
    isCalculoNDMaximoProduto() {
      return this.resumoExec.tipoCalculoValorMaximoNd && this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO';
    },
  },
  methods: {
    getMoney,
    getPercent,
    getNumber,
    headersPercentual() {
      this.header = [
        {
          text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', class: 'nowrap',
        },
        {
          text: this.$tc('label.familia', 1), value: 'codNomeFamilia', class: 'nowrap',
        },
        {
          text: this.$tc('label.produtos_selecionados', 1), value: 'codNomeProduto', class: 'nowrap',
        },
        {
          text: this.$tc('label.produtos_apurados', 1), value: 'codNomeProdutoApurado', class: 'nowrap',
        },
        {
          text: this.$tc('label.valor_negociado', 1), value: 'valor', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.apuracao_total', 1), value: 'apuracaoTotal', class: 'nowrap', cellClass: 'nowrap',
        },
      ];
      if (this.resumoExec
          && (!this.resumoExec.tipoCalculoValorMaximoNd
            || this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO')) {
        this.header.push({
          text: this.$tc('label.apuracao_final', 1), value: 'apuracaoFinal', class: 'nowrap', cellClass: 'nowrap',
        });
      }
      if (this.resumoExec && this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO') {
        this.header.push({
          text: this.$tc('label.valor_maximo_nd', 1), value: 'valorMaximoNd', class: 'nowrap', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.verba_nao_capturada', 1), value: 'verbaNaoCapturada', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.verba_acima_teto', 1), value: 'verbaAcimaDoTeto', cellClass: 'nowrap',
        });
      }
      return this.header;
    },
    headersGatilho() {
      this.header = [
        {
          text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', class: 'nowrap',
        },
        {
          text: this.$tc('label.familia', 1), value: 'codNomeFamilia', class: 'nowrap',
        },
        {
          text: this.$tc('label.produtos_selecionados', 1), value: 'codNomeProduto', class: 'nowrap',
        },
        {
          text: this.$tc('label.produtos_apurados', 1), value: 'codNomeProdutoApurado', class: 'nowrap',
        },
        {
          text: this.$tc('label.realizado_total', 1), value: 'realizadoPrevia', width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.apuracao_total', 1), value: 'apuracaoTotal', class: 'nowrap', cellClass: 'nowrap',
        },
      ];
      if (this.resumoExec
          && (!this.resumoExec.tipoCalculoValorMaximoNd
            || this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO')) {
        this.header.push({
          text: this.$tc('label.realizado_final', 1), value: 'realizadoFinal', width: '10%', class: 'nowrap', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.apuracao_final', 1), value: 'apuracaoFinal', class: 'nowrap', cellClass: 'nowrap',
        });
      }
      if (this.resumoExec && this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO') {
        this.header.push({
          text: this.$tc('label.valor_maximo_nd', 1), value: 'valorMaximoNd', class: 'nowrap', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.verba_nao_capturada', 1), value: 'verbaNaoCapturada', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.verba_acima_teto', 1), value: 'verbaAcimaDoTeto', cellClass: 'nowrap',
        });
      }
      return this.header;
    },
    headersValorPeca() {
      this.header = [
        {
          text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', class: 'nowrap',
        },
        {
          text: this.$tc('label.familia', 1), value: 'codNomeFamilia', class: 'nowrap',
        },
        {
          text: this.$tc('label.produtos_selecionados', 1), value: 'codNomeProduto', class: 'nowrap',
        },
        {
          text: this.$tc('label.produtos_apurados', 1), value: 'codNomeProdutoApurado', class: 'nowrap',
        },
        {
          text: this.$tc('label.quantidade_negociada', 1), value: 'quantidade', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_negociado', 1), value: 'valor', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.quantidade_total', 1), value: 'realizadoPrevia', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.apuracao_total', 1), value: 'apuracaoTotal', class: 'nowrap', cellClass: 'nowrap',
        },
      ];
      if (this.resumoExec
          && (!this.resumoExec.tipoCalculoValorMaximoNd
            || this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO')) {
        this.header.push({
          text: this.$tc('label.quantidade_final', 1), value: 'realizadoFinal', width: '10%', class: 'nowrap', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.apuracao_final', 1), value: 'apuracaoFinal', class: 'nowrap', cellClass: 'nowrap',
        });
      }
      if (this.resumoExec && this.resumoExec.tipoCalculoValorMaximoNd === 'CALCULO_PRODUTO_ND_MAXIMO') {
        this.header.push({
          text: this.$tc('label.valor_maximo_nd', 1), value: 'valorMaximoNd', class: 'nowrap', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.verba_nao_capturada', 1), value: 'verbaNaoCapturada', cellClass: 'nowrap',
        });
        this.header.push({
          text: this.$tc('label.verba_acima_teto', 1), value: 'verbaAcimaDoTeto', cellClass: 'nowrap',
        });
      }
      return this.header;
    },
    buscarProdutos() {
      const { idAcao } = this.$route.params;
      this.apuracaoAcaoListagemResource.buscarProdutosApuracaoAcao({ idAcao })
        .then((response) => {
          this.produtos = response.data;
          this.paginaCarregada = true;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    abrirModalMetasGatilho(produtos, tipoRealizado) {
      const { tipoCalculo, tipoGatilho } = this.resumoExec;
      this.$refs.modalMetasGatilho.abrirModal(produtos.metas, tipoRealizado, tipoCalculo, tipoGatilho);
    },
    visualizarLinhaProduto(linhaProduto, isLinhaProdutoApurado) {
      const { idGrupoFornecedor } = this.resumo;
      if (idGrupoFornecedor) {
        linhaProduto.idGrupoFornecedor = idGrupoFornecedor;
      }
      this.isLinhaProdutoApurado = isLinhaProdutoApurado;

      this.linhaProduto = linhaProduto;
      this.$refs.modalSelecaoProduto.open();
    },
    consultarNotas() {
      const { idAcao } = this.$route.params;
      const route = this.$router.resolve({ name: 'ver-notas-debito-consulta', params: { idAcao } });
      window.open(route.href, '_blank');
    },
    valorBarraProgresso(metas, tipoRealizado) {
      const qtdMetas = metas.length;
      let ordemFaixaAtingida = 0;
      metas.forEach((meta, index) => {
        if ((tipoRealizado === 'realizadoTotal' && meta.faixaAtingidaPrevia)
          || (tipoRealizado === 'realizadoFinal' && meta.faixaAtingida)) {
          ordemFaixaAtingida += index + 1;
        }
      });
      return (ordemFaixaAtingida * 100) / qtdMetas;
    },
    valorExecucao(valorApuracao, valorMaximoNd) {
      return ((valorApuracao / valorMaximoNd || 0) * 100).toFixed(2);
    },
  },
  beforeMount() {
    if (!this.resumoExec) {
      this.$router.push({ name: 'apuracao-acao' });
    } else {
      this.resumo = this.resumoExec;
    }
  },
  mounted() {
    if (this.resumoExec) {
      this.buscarProdutos();
    }
  },
};
</script>
<style lang="css">
.tabelaProdutos .v-progress-linear__determinate {
  height: 100% !important;
}
.tabelaProdutos .v-progress-linear__content {
  color: white;
  font-weight: bold;
}
</style>
